import { i18n } from "./locales/i18n";
import { createPinia } from "pinia";
import { createApp } from "vue";

import PrimeVue from "primevue/config";
import Button from "primevue/button";
import Checkbox from "primevue/checkbox";
import InputText from "primevue/inputtext";
import Menu from "primevue/menu";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import InputSwitch from "primevue/inputswitch";
import Chips from "primevue/chips";
import Panel from "primevue/panel";
import InputNumber from "primevue/inputnumber";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import InputMask from "primevue/inputmask";
import PanelMenu from "primevue/panelmenu";
import Dropdown from "primevue/dropdown";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import ConfirmPopup from "primevue/confirmpopup";
import SplitButton from "primevue/splitbutton";
import Toast from "primevue/toast";
import Tooltip from "primevue/tooltip";
import MultiSelect from "primevue/multiselect";
import Editor from "primevue/editor";
import FileUpload from "primevue/fileupload";
import OverlayPanel from "primevue/overlaypanel";
import ContextMenu from "primevue/contextmenu";
import AutoComplete from "primevue/autocomplete";
import Badge from "primevue/badge";
import BadgeDirective from "primevue/badgedirective";
import { FloatLabelInput } from "@cumulus/components";
import { FloatLabelDropdown } from "@cumulus/components";
import { FloatLabelDropdownPanel } from "@cumulus/components";
import { FloatLabelInputNumber } from "@cumulus/components";

import { vue3Debounce } from "vue-debounce";

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();

  app.use(PrimeVue);
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("PanelMenu", PanelMenu);
  app.component("PrimeButton", Button);
  app.component("Checkbox", Checkbox);
  app.component("InputText", InputText);
  app.component("PrimeMenu", Menu);
  app.component("DataTable", DataTable);
  app.component("Column", Column);
  app.component("ColumnGroup", ColumnGroup);
  app.component("InputSwitch", InputSwitch);
  app.component("Chips", Chips);
  app.component("Panel", Panel);
  app.component("InputNumber", InputNumber);
  app.component("PrimeDialog", Dialog);
  app.component("ProgressSpinner", ProgressSpinner);
  app.component("InputMask", InputMask);
  app.component("Dropdown", Dropdown);
  app.component("Toast", Toast);
  app.component("MultiSelect", MultiSelect);
  app.component("ContextMenu", ContextMenu);
  app.component("Editor", Editor);
  app.component("FileUpload", FileUpload);
  app.component("ConfirmPopup", ConfirmPopup);
  app.component("SplitButton", SplitButton);
  app.component("OverlayPanel", OverlayPanel);
  app.component("AutoComplete", AutoComplete);
  app.component("FloatLabelInput", FloatLabelInput);
  app.component("FloatLabelDropdown", FloatLabelDropdown);
  app.component("FloatLabelDropdownPanel", FloatLabelDropdownPanel);
  app.component("FloatLabelInputNumber", FloatLabelInputNumber);
  app.component("Badge", Badge);

  app.directive("badge", BadgeDirective);
  app.directive("tooltip", Tooltip);

  app.directive(
    "debounce",
    vue3Debounce({
      listenTo: "input",
    })
  );
}
