<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t(`product.warehouse.dialog-header`)"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @keydown.esc.stop="escapeDialog"
  >
    <div class="c-warehouse-add-dialog">
      <DataTable
        :value="filteredWarehouses"
        class="c-compact-datatable"
        dataKey="id"
        data-testid="product-warehouse-add"
        filterDisplay="menu"
        :autoLayout="true"
        responsiveLayout="scroll"
        selectionMode="single"
        @row-select="onRowSelect"
        :globalFilterFields="['name']"
        stripedRows
      >
        <template #empty>{{ t(`product.warehouse.no-warehouse-found`) }}</template>

        <Column field="name" :header="t(`product.warehouse.name`)" :sortable="true">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>
      </DataTable>
    </div>

    <template #footer>
      <PrimeButton
        :label="t(`common.cancel`)"
        data-testid="cancel-btn"
        @click="escapeDialog"
        class="c-dialog-default-button"
        autofocus
      />
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { Warehouse } from "@/repositories/warehouse/model/Warehouse";
import { DataTableRowSelectEvent } from "primevue/datatable";

const { t } = useI18n();

const props = defineProps<{
  showDialogAdd: boolean;
  selectedWarehouseIds: string[];
  warehouses: Warehouse[];
}>();

const emit = defineEmits<{
  (e: "addWarehouse", value: string): void;
  (e: "update:showDialogAdd", value: boolean): void;
}>();

const visible = computed({
  get: () => props.showDialogAdd,
  set: (value) => emit("update:showDialogAdd", value),
});

const filteredWarehouses = computed<Warehouse[]>(() => {
  const result = props.warehouses.filter((warehouse: Warehouse) => {
    const index = props.selectedWarehouseIds?.findIndex((c: string) => c === warehouse.id);
    return index === -1;
  });
  return result;
});

const onRowSelect = (event: DataTableRowSelectEvent) => {
  const warehouse = event.data as Warehouse;
  emit("addWarehouse", warehouse.id);
  escapeDialog();
};

const escapeDialog = () => {
  visible.value = false;
  document.getElementById("add-warehouse-button")?.focus();
};
</script>

<style lang="scss">
.c-warehouse-add-dialog {
  padding: 0 1.5rem;
  border: none;
}
</style>
