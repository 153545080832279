<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton class="c-circular-button mr-3" @click="router.push({ name: 'product-add' })">
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-3">{{ t("product.title-add") }}</span>
        </PrimeButton>
        <PrimeButton
          class="c-circular-button mr-3"
          @click="toggleOptionsMenu"
          aria-haspopup="true"
          aria-controls="overlay_menu"
        >
          <i class="pi pi-ellipsis-h c-default-button c-circular-icon"></i>
          <span class="px-3">{{ t("common.options") }}</span>
        </PrimeButton>
        <PrimeMenu ref="optionsMenu" id="overlay_menu" :model="items" :popup="true" />
      </div>
      <div class="c-header-filter-container c-header-icon-container">
        <div>
          <FilterOverview v-model:filters="filtersComputed" :manufacturers="manufacturers" />
        </div>
        <PrimeButton class="c-circular-button" @click="emit('toggleFilterSideBarClicked')">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-3">{{
            showSidebar ? t("product.search-filters.hide-filter") : t("product.search-filters.show-filter")
          }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";

import { ProductSearchFilters } from "@/repositories/search/model/ProductSearchFilters";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import FilterOverview from "./FilterOverview.vue";
import { ProductHierarchyRoutes } from "@/product-hierarchy/routes";

const router = useRouter();
const { t } = useI18n();

const toggleOptionsMenu = (event: Event) => {
  optionsMenu.value.toggle(event);
};

const optionsMenu = ref();
const items = ref([
  {
    label: t("common.options"),
    items: [
      {
        label: t("attribute.label"),
        icon: "c-option-icon c-attribute-icon",
        command: () => {
          router.push({ name: "attributes" });
        },
      },
      {
        label: t("product-hierarchy-toolbar.product-hierarchy"),
        icon: "c-option-icon c-group-level-icon",
        command: () => {
          router.push({ name: ProductHierarchyRoutes.productHierarchies });
        },
      },
    ],
  },
]);

const props = defineProps<{
  showSidebar: boolean;
  filters: ProductSearchFilters;
  manufacturers: Manufacturer[];
}>();

const emit = defineEmits<{
  (e: "update:filters", value: ProductSearchFilters): void;
  (e: "toggleFilterSideBarClicked"): void;
}>();
const filtersComputed = computed<ProductSearchFilters>({
  get() {
    return props.filters;
  },
  set(value: ProductSearchFilters) {
    emit("update:filters", value);
  },
});
</script>

<style lang="scss">
.c-option-icon::before {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-color: var(--custom-icon-letter-bg-color);
  color: var(--text-color);
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
}

.c-attribute-icon::before {
  content: "A";
}

.c-group-level-icon::before {
  content: "G";
}
</style>
