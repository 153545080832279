import { httpClient, AuthHeaders } from "@cumulus/http";
import { WarehouseProduct } from "./model/WarehouseProduct";

class WarehouseProductApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != undefined && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE as string}`
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getByProductId(authHeaders: AuthHeaders, productId: string): Promise<WarehouseProduct[]> {
    return await httpClient(this.uri, authHeaders)
      .get(`/warehouses/products/${productId}`)
      .then((response) => response.data);
  }
}
const warehouseProductApi = new WarehouseProductApi();
export { warehouseProductApi };
