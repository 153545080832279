<template>
  <label for="manufacturer-status"> {{ t(`product.manufacturer.status`) }} </label>
  <Dropdown
    id="manufacturer-status"
    optionLabel="name"
    optionValue="code"
    data-testid="manufacturer-status"
    v-model="selectedManufacturerStatusComputed"
    aria-describedby="manufacturer-status-help"
    class="inputfield w-full"
    :options="manufacturerStatusTypesComputed"
    :placeholder="t('placeholder.select', { property: t('product.manufacturer.status').toLowerCase() })"
  />
</template>

<script setup lang="ts">
import { ManufacturerStatus } from "@/repositories/manufacturer/model/ManufacturerStatus";
import { Options } from "@/repositories/manufacturer/model/Options";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  manufacturerStatus: ManufacturerStatus;
}>();

const emit = defineEmits<{
  (e: "update:manufacturerStatus", value: ManufacturerStatus): void;
}>();

const { t } = useI18n();

const manufacturerStatusTypesComputed = computed(() => {
  const manufacturerStatusTypes: Options[] = [];
  for (const status in ManufacturerStatus) {
    manufacturerStatusTypes.push({
      name: t(`product.manufacturer.${status.toLowerCase()}`),
      code: status,
    });
  }
  return manufacturerStatusTypes;
});

const selectedManufacturerStatusComputed = computed({
  get() {
    return props.manufacturerStatus;
  },
  set(value: ManufacturerStatus) {
    emit("update:manufacturerStatus", value);
  },
});
</script>
