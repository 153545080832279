<template>
  <div v-if="Array.isArray(values)">
    <li v-for="(value, index) in values" :key="index" class="c-chips-token">
      <span>{{ value }}</span>
      <span class="c-chips-token-icon pi pi-times-circle" @click="emit('removeFilter', filterType, index)"> </span>
    </li>
  </div>
  <li v-else-if="values" class="c-chips-token">
    <span>{{ values }}</span>
    <span class="c-chips-token-icon pi pi-times-circle" @click="emit('removeFilter', filterType, 0)"> </span>
  </li>
</template>

<script setup lang="ts">
import { ProductSearchFilters } from "@/repositories/search/model/ProductSearchFilters";

defineProps<{
  values: string[] | string;
  filterType: keyof ProductSearchFilters;
}>();

const emit = defineEmits<{
  (event: "removeFilter", type: keyof ProductSearchFilters, index: number): void;
}>();
</script>

<style scoped lang="scss">
.c-chips-token {
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  color: var(--chips-text-secondary);
  border-radius: 1rem;
  background-color: var(--chips-bg-secondary);
  display: inline-flex;
  align-items: center;
}

.c-chips-token-icon {
  margin-left: 0.5rem;
  cursor: pointer;
}
</style>
