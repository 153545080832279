<template>
  <div class="mt-4">
    <FloatLabelInput
      :inputType="'text'"
      :label="t('product.name')"
      v-model:value="productNameComputed"
      id="product-name"
      :focusInput="focusInput"
      :placeholder="t('placeholder.type', { property: t('product.name').toLowerCase() })"
      dataTestId="product-name"
      :isRequired="true"
      :maxlength="8000"
    />
    <small class="p-error" v-if="val.name.$error" data-testid="product-name-error">
      {{ val.name.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  name: string;
  focusInput: boolean;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const rules = {
  name: {
    required: required,
  },
};

const val = useVuelidate(rules, props);

const productNameComputed = computed<string>({
  get: () => {
    return props.name ? props.name : "";
  },
  set: (value) => {
    emit("update:name", value);
  },
});
</script>
