import { httpClient, AuthHeaders } from "@cumulus/http";
import { Tax } from "./model/Tax";

class TaxApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != undefined && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}/taxes`
        : `${import.meta.env.VITE_APP_API_URL as string}/taxes`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Tax[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }
}

const taxApi = new TaxApi();
export { taxApi };
