<template>
  <div class="c-product-image-upload" :tabindex="0" @keydown="handleKeydown">
    <div class="col-12 md:col-6 lg:col-5 p-0">
      <FileUpload
        accept="image/jpeg,image/png,image/webp"
        :multiple="true"
        :maxFileSize="10000000"
        :auto="true"
        :customUpload="true"
        @uploader="uploader($event as unknown as FileUploadUploadEvent)"
        :disabled="uploadInProgress"
        data-testid="product-images-upload"
        v-if="!uploadInProgress"
      >
        <template #content>
          <div
            v-if="productImages.length > 0"
            ref="galleryContainer"
            id="gallery-wrapper"
            :style="{
              height: '100%',
            }"
          >
            <Galleria
              ref="galleria"
              v-model:activeIndex="activeIndex"
              :value="productImages"
              :containerStyle="{ 'max-width': '100%', height: '100%' }"
              :showThumbnails="true"
              :numVisible="fullScreen ? 6 : 2"
              :showItemNavigators="fullScreen"
              :circular="true"
              :transitionInterval="3000"
              :responsiveOptions="responsiveOptions"
              :pt="{
                root: {
                  class: [{ 'flex flex-column': fullScreen }],
                },
                content: {
                  class: ['relative', { 'flex-1 justify-content-center': fullScreen }],
                  style: { height: '100%' },
                },
                thumbnailwrapper: 'absolute w-full left-0 bottom-0',
                previousThumbnailButton: { tabindex: '-1' },
                nextThumbnailButton: { tabindex: '-1' },
              }"
            >
              <template #item="slotProps">
                <img
                  :src="resizeImage(slotProps.item.url, 200, 200)"
                  :alt="slotProps.item.name"
                  :style="[
                    {
                      maxWidth: !fullScreen ? '150px' : '50vw',
                      height: !fullScreen ? '200px' : '60vh',
                      borderTopLeftRadius: !fullScreen ? '12px' : '',
                      borderBottomLeftRadius: !fullScreen ? '12px' : '',
                    },
                  ]"
                  @click="toggleFullScreen"
                />
              </template>
              <template #thumbnail="slotProps">
                <div class="grid grid-nogutter">
                  <img
                    :src="resizeImage(slotProps.item.url, 50, 50)"
                    :alt="slotProps.item.name"
                    style="height: 50px; width: 50px; display: block"
                  />
                </div>
              </template>
            </Galleria>
          </div>
          <div v-else class="flex align-items-center justify-content-center flex-column p-3">
            <i class="pi pi-cloud-upload border-2 border-circle p-4 text-8xl text-400 border-400" />
            <p class="mb-1 p-1">{{ t("product.image.drag-and-drop") }}</p>
          </div>
        </template>
      </FileUpload>
      <div class="h-full flex align-items-center" v-if="uploadInProgress">
        <ProgressSpinner></ProgressSpinner>
      </div>
    </div>
    <div class="flex flex-column justify-content-between col pb-0">
      <div class="p-2">
        <div class="mt-4">
          <FloatLabelInput
            :inputType="'text'"
            :label="'Title'"
            v-model:value="imageTitleComputed"
            :tabIndex="tabIndexRef"
            id="image-title-input"
          />
        </div>
        <div class="mt-5">
          <FloatLabelInput
            :inputType="'text'"
            :label="'Description'"
            v-model:value="imageDescriptionComputed"
            :tabIndex="tabIndexRef"
            id="image-description-input"
          />
        </div>
      </div>
      <div class="flex justify-content-between align-items-center p-2">
        <div class="c-sort-images-btn">
          <PrimeButton class="p-button-text" :tabindex="-1" id="sort-images-btn" @click="openSortImagesModal">
            <span class="material-symbols-outlined"> perm_media </span>
            {{ t("product.image.manage") }}
          </PrimeButton>
        </div>

        <div class="text-right c-image-upload-btn-only">
          <FileUpload
            accept="image/jpeg,image/png,image/webp"
            :multiple="true"
            :maxFileSize="10000000"
            :auto="true"
            :customUpload="true"
            @uploader="uploader($event as unknown as FileUploadUploadEvent)"
            v-if="!uploadInProgress"
            :tabindex="-1"
          >
            <template #header="{ chooseCallback }">
              <PrimeButton
                @click="chooseCallback()"
                class="p-button-text"
                data-testid="product-images-upload-btn"
                :disabled="uploadInProgress"
                :tabindex="tabIndexRef"
                id="upload-btn"
              >
                <span class="material-symbols-outlined"> upload </span>
              </PrimeButton>
            </template>
          </FileUpload>
        </div>
      </div>
    </div>
  </div>
  <PrimeDialog
    v-model:visible="sortImages"
    @hide="closeSortImagesModal"
    contentClass="h-full"
    modal
    @keydown.esc.stop="sortImages = false"
    v-if="productImages.length > 0"
  >
    <div class="grid md:p-4 pt-0 c-manage-images">
      <div
        v-for="(image, index) of productImages"
        :key="index"
        class="col-4 relative md:p-4"
        @dragover.prevent
        @drop="handleDrop(index)"
        v-badge="index + 1"
        style="font-size: 2rem"
      >
        <img
          :src="resizeImage(image.url, 200, 200)"
          :alt="image.name"
          class="p-overlay-badge c-image"
          @dragstart="dragIndex = index"
        />
        <PrimeButton
          class="p-button-xs p-button-secondary c-image-delete"
          data-testid="delete-product-image"
          @click="onConfirmDelete($event, image.url)"
          ><span class="material-symbols-outlined"> delete_forever </span>
        </PrimeButton>
      </div>
    </div>
  </PrimeDialog>
</template>
<script setup lang="ts">
import ProgressSpinner from "primevue/progressspinner";
import { useI18n } from "vue-i18n";

import { useAuth } from "@cumulus/event-bus";
import { FileUploadUploadEvent } from "primevue/fileupload";

import FileUpload from "primevue/fileupload";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { ProductImage } from "@/repositories/product/model/ProductImage";
import { useImageService } from "@/repositories/image/ImageService";
import Galleria from "primevue/galleria";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const { t } = useI18n();

const { getAuthHeaders } = useAuth();
const { uploadImage } = useImageService();
const uploadInProgress = ref(false);

const sortImages = ref(false);
const activeIndex = ref(0);
const tabIndexRef = ref(0);
const fullScreen = ref(false);

const props = defineProps<{
  productImages: ProductImage[];
}>();

const emit = defineEmits<{
  (e: "addImage", value: ProductImage): void;
  (e: "removeImage", value: string): void;
  (e: "updateImageOrder", value: ProductImage[]): void;
  (e: "updateImageName", value: { index: number; name: string }): void;
  (e: "updateImageDescription", value: { index: number; description: string }): void;
}>();
const { resizeImage } = useImageService();

const galleryContainer = ref<HTMLElement>();

const responsiveOptions = ref([
  {
    breakpoint: "992px",
    numVisible: 3,
  },
  {
    breakpoint: "575px",
    numVisible: 1,
  },
]);
const dragIndex = ref<null | number>(null);

const handleDrop = (index: number) => {
  const dropIndex = index;

  if (dragIndex.value !== null && dropIndex !== dragIndex.value) {
    const localProductImages = [...props.productImages];
    const draggedImage = localProductImages[dragIndex.value];
    const targetImage = localProductImages[dropIndex];
    localProductImages.splice(dropIndex, 1, draggedImage);
    localProductImages.splice(dragIndex.value, 1, targetImage);
    dragIndex.value = null;

    emit("updateImageOrder", localProductImages);
  }
};

const uploader = async (event: FileUploadUploadEvent) => {
  const files = event.files;
  if (files !== undefined && files !== null) {
    uploadInProgress.value = true;

    try {
      const authHeaders = await getAuthHeaders();
      for (const file of files as File[]) {
        const imageInfo = await uploadImage(file, authHeaders);
        emit("addImage", imageInfo);
      }
    } finally {
      uploadInProgress.value = false;
      document.getElementById("gallery-wrapper")?.focus();
    }
  }
};

const uploadOnKeyPress = () => {
  const uploadButton = document.getElementById("upload-btn");
  if (uploadButton) {
    uploadButton.focus();
    uploadButton.click();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Enter") {
    event.preventDefault();
    if (props.productImages.length > 0) {
      const titleInput = document.getElementById("image-title-input");
      if (titleInput) {
        titleInput.focus();
      }
    } else {
      uploadOnKeyPress();
    }
  } else if (event.key === "Tab") {
    const titleInput = document.getElementById("image-title-input");
    if (document.activeElement === titleInput) {
      tabIndexRef.value = 0;
    } else {
      tabIndexRef.value = -1;
    }
  }
};

const onConfirmDelete = (event: Event, imageUrl: string) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      emit("removeImage", imageUrl);
    },
  });
};
const openSortImagesModal = () => {
  sortImages.value = true;
};
const closeSortImagesModal = () => {
  sortImages.value = false;
};

const imageTitleComputed = computed<string>({
  get: () => {
    return props.productImages[activeIndex.value]?.name ? props.productImages[activeIndex.value].name : "";
  },
  set: (value) => {
    emit("updateImageName", { index: activeIndex.value, name: value });
  },
});

const imageDescriptionComputed = computed<string>({
  get: () => {
    return props.productImages[activeIndex.value]?.description
      ? props.productImages[activeIndex.value].description
      : "";
  },
  set: (value) => {
    emit("updateImageDescription", { index: activeIndex.value, description: value });
  },
});

const toggleFullScreen = () => {
  if (fullScreen.value) {
    closeFullScreen();
  } else {
    openFullScreen();
  }
};

const onFullScreenChange = () => {
  fullScreen.value = !fullScreen.value;
};
const galleria = ref();
const openFullScreen = () => {
  const galleryWrapper = galleria.value.$el;
  if (galleryWrapper) {
    switch (true) {
      case galleryWrapper.requestFullscreen !== undefined:
        galleryWrapper.requestFullscreen();
        break;
      case galleryWrapper.mozRequestFullScreen !== undefined:
        galleryWrapper.mozRequestFullScreen();
        break;
      case galleryWrapper.webkitRequestFullscreen !== undefined:
        galleryWrapper.webkitRequestFullscreen();
        break;
      case galleryWrapper.msRequestFullscreen !== undefined:
        galleryWrapper.msRequestFullscreen();
        break;
      default:
        break;
    }
  }
};

interface Document {
  mozCancelFullScreen?: () => Promise<void>;
  msExitFullscreen?: () => Promise<void>;
  webkitExitFullscreen?: () => Promise<void>;
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  webkitFullscreenElement?: Element;
}

const closeFullScreen = () => {
  switch (true) {
    case document.exitFullscreen !== undefined:
      document.exitFullscreen();
      break;
    case (document as Document).mozCancelFullScreen !== undefined:
      (document as Document).mozCancelFullScreen?.();
      break;
    case (document as Document).webkitExitFullscreen !== undefined:
      (document as Document).webkitExitFullscreen?.();
      break;
    case (document as Document).msExitFullscreen !== undefined:
      (document as Document).msExitFullscreen?.();
      break;
    default:
      break;
  }
};

const bindDocumentListeners = () => {
  document.addEventListener("fullscreenchange", onFullScreenChange);
  document.addEventListener("mozfullscreenchange", onFullScreenChange);
  document.addEventListener("webkitfullscreenchange", onFullScreenChange);
  document.addEventListener("msfullscreenchange", onFullScreenChange);
};

const unbindDocumentListeners = () => {
  document.removeEventListener("fullscreenchange", onFullScreenChange);
  document.removeEventListener("mozfullscreenchange", onFullScreenChange);
  document.removeEventListener("webkitfullscreenchange", onFullScreenChange);
  document.removeEventListener("msfullscreenchange", onFullScreenChange);
};

onMounted(() => {
  bindDocumentListeners();
});

onBeforeUnmount(() => {
  unbindDocumentListeners();
});
</script>

<style scoped lang="scss">
@use "sass:math";
$margin: 0.25rem;
$imgSize: calc(math.div(100%, 3) - math.div($margin * 2, 3));
.c-product-image-upload {
  border-radius: var(--card-border-radius);
  outline: none;
  display: flex;
  height: 100%;
  :deep(.p-button-label) {
    display: none;
  }

  &:focus {
    box-shadow: 0 0 0 0.1rem var(--active-component-color);
  }
  &:focus-within > #upload-btn {
    background-color: var(--primary-color);
    color: #fff;
    box-shadow: none;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}

.c-image {
  cursor: pointer;
  width: 100%;
  height: 180px;

  @media (min-width: 767px) {
    height: 250px;
  }
}

.c-manage-images {
  width: 50vw;

  @media (max-width: 767px) {
    width: 95vw;
  }
}
.c-image-delete {
  padding: 0.5rem;
  position: absolute;
  bottom: 2rem;
  right: 1.5rem;
  @media (max-width: 767px) {
    padding: 0.1rem;
    bottom: 1rem;
    right: 0.5rem;
  }
}

.c-sort-images-btn {
  .p-button {
    padding: 0;
  }
  .p-button.p-button-text:enabled:hover {
    background: transparent;
  }
  .p-button.p-button-text:enabled:focus {
    background: transparent;
    box-shadow: none;
  }
}

.c-image-upload-btn-only {
  :deep(.p-fileupload .p-fileupload-buttonbar) {
    display: block;
    padding: 0.3rem;
    background-color: transparent;
    border: none;
  }
  :deep(.p-fileupload .p-fileupload-buttonbar .p-button) {
    padding: 0;
  }

  :deep(.p-fileupload .p-fileupload-content) {
    display: none;
  }
}
:deep(.p-fileupload) {
  height: 100%;
}
:deep(.p-fileupload-content) {
  border: none;
  padding: 0;
  border-radius: var(--card-border-radius);
  height: 100%;
}

:deep(.p-fileupload .p-fileupload-buttonbar) {
  display: none;
}
:deep(.p-fileupload-choose:not(.p-disabled):hover) {
  background: transparent;
  color: var(--primary-color);
  border-color: transparent;
}

:deep(.p-fileupload .p-fileupload-buttonbar .p-button) {
  margin: 0;
}

:deep(.p-overlay-badge .p-badge) {
  top: 20px;
  right: 20px;
}
</style>
<style>
@keyframes p-progress-spinner-color {
  100%,
  0% {
    stroke: var(--blue-100);
  }
  40% {
    stroke: var(--blue-100);
  }
  66% {
    stroke: var(--blue-100);
  }
  80%,
  90% {
    stroke: var(--blue-100);
  }
}
</style>
