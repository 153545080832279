<template>
  <div class="pb-2">
    <AttributeButton
      :attributeType="AttributeType.Name"
      @showAttributePanel="showAttributePanel($event)"
      :label="t('product.attribute.label')"
      :selectLabel="t('product.attribute.select', 1)"
      id="add-attribute-dropdown"
    />
    <AttributePanel
      ref="attributeSelectPanelRef"
      :attributeType="AttributeType.Name"
      :productAttributes="productAttributes"
      @onAttributeSelect="onAttributeSelect"
    />
  </div>
</template>

<script setup lang="ts">
import AttributePanel from "./AttributePanel.vue";
import AttributeButton from "./AttributeButton.vue";
import { AttributeType } from "@/repositories/attribute/model/AttributeType";
import { nextTick, ref } from "vue";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { Attribute } from "@/repositories/attribute/model/Attribute";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  productAttributes: ProductAttribute[];
}>();

const emit = defineEmits<{
  (e: "onAttributeSelect", attribute: ProductAttribute): void;
}>();

const attributeSelectPanelRef = ref();
const { t } = useI18n();

const showAttributePanel = (event: Event) => {
  attributeSelectPanelRef.value.toggle(event);
};

const onAttributeSelect = (selectedAttribute: Attribute) => {
  const attribute: ProductAttribute = {
    id: selectedAttribute.id,
    name: selectedAttribute.name,
    values: [],
    attributeState: selectedAttribute.attributeState,
  };
  emit("onAttributeSelect", attribute);

  const selectedIndex = props.productAttributes.findIndex((attr) => attr.id === selectedAttribute.id);

  if (selectedIndex !== -1) {
    nextTick(() => {
      document.getElementById(`add-product-attribute-values-${selectedIndex}`)?.focus();
    });
  }
};
</script>
