<template>
  <PrimeDialog
    class="c-accessory-add-dialog"
    contentClass="h-full"
    v-model:visible="visible"
    :header="t('product.accessory.dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    @hide="onDialogHide"
    @keydown.esc.stop="visible = false"
  >
    <div class="c-accessory-add-dialog-content" tabindex="2">
      <div class="flex justify-content-center">
        <ProductSearchInput
          class="w-full my-2"
          :pageSize="pageSize"
          ref="productSearchInput"
          :page="page"
          @update:searchResults="onSearchResultsUpdated"
          @update:isSearching="($event: boolean) => (loading = $event)"
          @focus-search-result="onFocusSearchResult"
          autofocus
        />
      </div>
      <DataTable
        :value="searchResultsComputed"
        v-model:selection="selectedProducts"
        dataKey="id"
        :autoLayout="true"
        :lazy="true"
        ref="searchResultTable"
        :paginator="true"
        responsiveLayout="scroll"
        selectionMode="multiple"
        class="c-compact-datatable"
        stripedRows
        :loading="loading"
        :rows="pageSize"
        data-testid="product-accessories-table"
        :totalRecords="searchResultsComputed.length"
        :currentPageReportTemplate="
          t('common.current-page-template', {
            first: '{first}',
            last: '{last}',
            totalRecords: '{totalRecords}',
          })
        "
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        @page="onPage"
      >
        <template #empty>{{ t("product.accessory.no-accessory-found") }}</template>

        <Column field="productNumber" :header="t('product.accessory.number')">
          <template #body="{ data }">
            {{ data.productNumber }}
          </template>
        </Column>

        <Column field="name" :header="t('product.accessory.name')">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>
      </DataTable>
    </div>

    <template #footer>
      <PrimeButton
        :label="t('common.add')"
        data-testid="add-btn"
        @click="onAddProducts"
        class="c-dialog-default-button"
        severity="success"
        autofocus
      />
      <PrimeButton
        :label="t('common.cancel')"
        data-testid="cancel-btn"
        @click="visible = false"
        class="c-dialog-default-button"
        autofocus
      />
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { computed, ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { DataTablePageEvent } from "primevue/datatable";
import { Product } from "@/repositories/product/model/Product";
import ProductSearchInput from "./ProductSearchInput.vue";
import { useAccessoriesStore } from "@/stores/ProductAccessoryStore";
import { storeToRefs } from "pinia";

const loading = ref(false);
const searchResults = ref<Product[]>([]);
const selectedProducts = ref<Product[]>([]);
const page = ref(1);
const pageSize = 100;
const productSearchInput = ref();
const searchResultTable = ref();
const { addAccessories } = useAccessoriesStore();
const { product } = storeToRefs(useAccessoriesStore());

const searchResultsComputed = computed(() => {
  return "id" in product.value
    ? searchResults.value.filter((x) => !isCurrentProduct(x, product.value as Product) && !isAlreadySelected(x))
    : searchResults.value.filter((x) => !isAlreadySelected(x));
});

const isCurrentProduct = (searchProduct: Product, currentProduct: Product) => searchProduct.id === currentProduct.id;
const isAlreadySelected = (searchproduct: Product) =>
  product.value.accessoryProductIds.some((x) => x === searchproduct.id);

const onFocusSearchResult = () => {
  searchResultTable.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const onPage = async (event: DataTablePageEvent) => {
  page.value = event.page + 1;
};

const { t } = useI18n();

const props = defineProps<{
  showDialogAdd: boolean;
}>();

const onSearchResultsUpdated = (products: Product[]) => {
  searchResults.value = products;
};

const onAddProducts = () => {
  addAccessories(selectedProducts.value.map((x) => x.id));
  visible.value = false;
  clearSelectedProducts();
};

const clearSearchResult = () => {
  searchResults.value = [];
};

const clearSelectedProducts = () => {
  selectedProducts.value = [];
};

const onDialogHide = () => {
  clearSearchResult();
  clearSelectedProducts();
  focusAddAccessoryButton();
};

const emit = defineEmits<{
  (e: "update:showDialogAdd", value: boolean): void;
}>();

const visible = computed({
  get: () => props.showDialogAdd,
  set: (value) => emit("update:showDialogAdd", value),
});

const focusAddAccessoryButton = async () => {
  await nextTick();
  document.getElementById("add-accessory-button")?.focus();
};
</script>

<style lang="scss">
.c-accessory-add-dialog {
  height: 60%;
}

.c-accessory-add-dialog-content {
  padding: 0 1.5rem;
  border: none;
}
</style>
