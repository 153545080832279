<template>
  <FloatLabelDropdownPanel
    :id="`input-${props.id}`"
    :selectedItemName="selectedProductGroupNamesComputed.name ?? ''"
    :label="t(`product-hierarchy-dialog.label-${props.id}`)"
    :selectLabel="t('product-hierarchy-dialog.select-level')"
    @toggleDropdownPanel="showProductGroupNamesPanel"
    :dataTestId="`${props.id}`"
    :disabled="props.disabled"
    class="c-product-group-dropdown"
  />

  <OverlayPanel
    :id="`overlay-panel-${props.id}`"
    ref="productGroupNamesPanelRef"
    :class="`productGroupNamePanel`"
    appendTo="body"
    :dismissable="true"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '280px' }"
    @keydown.esc.stop="hideOverlayPanel"
    :style="{
      zIndex: '113 !important',
    }"
  >
    <div>
      <ProductGroupNameSearchList
        v-model:selectedProductGroupName="selectedProductGroupNamesComputed"
        @hideOverlayPanel="hideOverlayPanel"
      />

      <div class="formgrid grid mt-3">
        <div class="col-12 lg:col-12 flex flex-column">
          <div class="flex justify-content-end">
            <PrimeButton
              type="button"
              :label="t('common.close')"
              class="c-dialog-default-button"
              @click="hideOverlayPanel"
              :data-testid="`close-overlay-panel-${props.id}`"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </OverlayPanel>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import ProductGroupNameSearchList from "./ProductGroupNameSearchList.vue";
import { ProductGroupNameByLanguage } from "../model/ProductGroupNameByLanguage";

const props = defineProps<{
  id: string;
  selectedLevelId: string;
  productGroupNames: Array<ProductGroupNameByLanguage | null>;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:selectedLevelId", value: string): void;
}>();

const { t } = useI18n();
const productGroupNamesPanelRef = ref();

const showProductGroupNamesPanel = (event: Event) => {
  const key = (event as KeyboardEvent).key;
  if (key === "Shift" || key === "Escape" || key === "Tab") {
    return;
  }
  productGroupNamesPanelRef.value.toggle(event);
};

const hideOverlayPanel = () => {
  productGroupNamesPanelRef.value.hide();
  document.getElementById("product-group-names-dropdown")?.focus();
};

const selectedProductGroupNamesComputed = computed<ProductGroupNameByLanguage>({
  get: () =>
    props.productGroupNames?.find((productGroupName) => productGroupName?.id === props.selectedLevelId) ??
    new ProductGroupNameByLanguage(),

  set: (value) => {
    emit("update:selectedLevelId", value?.id ?? "");
    hideOverlayPanel();
  },
});
</script>
<style scoped lang="scss">
.c-product-group-dropdown {
  width: 100%;
}

.c-content {
  flex: 100%;
}
</style>
