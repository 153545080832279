<template>
  <OverlayPanel
    ref="salesUnitPanelRef"
    appendTo="body"
    :dismissable="true"
    id="sales_unit_overlay_panel"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '400px' }"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <SalesUnitForm
      v-if="openSalesUnitForm"
      :salesUnit="salesUnit"
      @onCancel="openSalesUnitForm = false"
      @onCreateSalesUnit="onCreateSalesUnit"
      @onUpdateSalesUnit="onUpdateSalesUnit"
      @onDeleteSalesUnit="onDeleteSalesUnit"
    />

    <div v-else>
      <SalesUnitList
        v-model:selectedSalesUnit="selectedSalesUnitComputed"
        @createNewSalesUnit="createNewSalesUnitFromSearch"
        @hideOverlayPanel="hideOverlayPanel"
      />

      <div class="formgrid grid mt-3">
        <div class="col-6 lg:col-6 flex flex-column">
          <div class="flex justify-content-start">
            <PrimeButton
              type="button"
              :label="t('common.close')"
              class="c-dialog-default-button"
              @click="hideOverlayPanel"
              data-testid="sales-unit-close-panel"
            ></PrimeButton>
          </div>
        </div>
        <div class="col-6 lg:col-6 flex flex-column">
          <div class="flex justify-content-end">
            <PrimeButton
              type="button"
              :label="t('common.create')"
              class="c-dialog-default-button"
              data-testid="sales-unit-create-new"
              @click="createNewSalesUnit"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </OverlayPanel>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { SalesUnit } from "@/repositories/sales-unit/model/SalesUnit";
import SalesUnitList from "./SalesUnitList.vue";
import SalesUnitForm from "./SalesUnitForm.vue";

const props = defineProps<{
  selectedSalesUnit: SalesUnit;
}>();

const emit = defineEmits<{
  (e: "update:selectedSalesUnit", value: SalesUnit): void;
}>();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const openSalesUnitForm = ref(false);
const salesUnitPanelRef = ref();
const salesUnit = ref<SalesUnit>(new SalesUnit());

const selectedSalesUnitComputed = computed<SalesUnit>({
  get: () => props.selectedSalesUnit,
  set: (value) => {
    emit("update:selectedSalesUnit", value);
    hideOverlayPanel();
  },
});

const onCreateSalesUnit = (su: SalesUnit) => {
  openSalesUnitForm.value = false;
  toast.add({
    severity: "success",
    summary: t("product.sales-unit.success-create.summary"),
    detail: t("product.sales-unit.success-create.detail", { name: su.name }),
    closable: true,
  });
};

const onUpdateSalesUnit = (su: SalesUnit) => {
  openSalesUnitForm.value = false;
  toast.add({
    severity: "success",
    summary: t("product.sales-unit.success-update.summary"),
    detail: t("product.sales-unit.success-update.detail", { name: su.name }),
    closable: true,
  });
};

const onDeleteSalesUnit = (su: SalesUnit) => {
  openSalesUnitForm.value = false;
  toast.add({
    severity: "success",
    summary: t("product.sales-unit.success-delete.summary"),
    detail: t("product.sales-unit.success-delete.detail", { name: su.name }),
    closable: true,
  });
};

const createNewSalesUnitFromSearch = (su: SalesUnit) => {
  salesUnit.value = su;
  openSalesUnitForm.value = true;
};

const createNewSalesUnit = () => {
  salesUnit.value = new SalesUnit();
  openSalesUnitForm.value = true;
};

const toggle = (event: Event) => {
  salesUnitPanelRef.value.toggle(event);
};

const hideOverlayPanel = () => {
  salesUnitPanelRef.value.hide();
  document.getElementById("sales-unit-dropdown")?.focus();
};

defineExpose({ toggle: toggle });
</script>
<style scoped lang="scss">
:deep(.p-datatable .p-datatable-header) {
  padding: 0;
  margin-bottom: 0.5rem;
  .p-inputtext {
    border: none;
    border-bottom: 2px solid #4d7694;
    border-radius: 0;

    &:focus {
      box-shadow: none;
      border-color: #59b5e8;
    }
  }
  .p-input-icon-left > i:first-of-type {
    color: #59b5e8;
    font-weight: bolder;
  }
}
</style>
