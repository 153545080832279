import { httpClient, AuthHeaders } from "@cumulus/http";
import { NewImage } from "./model/NewImage";
import { ImageInfo } from "./model/ImageInfo";

class ImageApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_IMAGE != undefined && import.meta.env.VITE_APP_API_IMAGE
        ? import.meta.env.VITE_APP_API_IMAGE + "/images"
        : `${import.meta.env.VITE_APP_API_URL as string}/images`;
  }

  public async upload(authHeaders: AuthHeaders, image: NewImage): Promise<ImageInfo> {
    return await httpClient(this.uri, authHeaders)
      .post("/", image)
      .then(({ data }) => data);
  }
}

const imageApi = new ImageApi();
export { imageApi };
