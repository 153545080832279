import { LanguageIsoType } from "./LanguageIsoType";
export class LanguageText {
  languageIso: LanguageIsoType = LanguageIsoType.English;
  text = "";

  public static createLanguageText(languageIso: LanguageIsoType, text: string): LanguageText {
    const languageText = new LanguageText();
    languageText.languageIso = languageIso;
    languageText.text = text;
    return languageText;
  }
}
