<template>
  <label for="attribute-state">{{ t("attribute.state") }}</label>
  <Dropdown
    id="attribute-state"
    v-model="selectedState"
    class="inputfield w-full"
    :options="Object.keys(AttributeState)"
    data-testid="attribute-state"
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { AttributeState } from "@/repositories/attribute/model/AttributeState";

const props = defineProps<{
  attributeState: AttributeState;
}>();

const emit = defineEmits<{
  (e: "update:attributeState", value: AttributeState): void;
}>();

const { t } = useI18n();
const isActive = ref(false);

const selectedState = computed<AttributeState>({
  get: (): AttributeState => {
    return props.attributeState;
  },
  set: (value: AttributeState) => {
    emit("update:attributeState", value);
  },
});
</script>
