<template>
  <div class="relative c-editor-container" :tabindex="0" id="editor-wrapper">
    <div id="product-info" class="flex-grow-1">
      <Editor
        ref="editorRef"
        v-model="productInfoComputed"
        :editorStyle="editorStyleHeight"
        @keydown.escape="handleEscape"
        @load="quillLoaded"
      >
      </Editor>
    </div>
  </div>
</template>

<script setup lang="ts">
import Editor from "primevue/editor";
import { computed, ref, nextTick } from "vue";

const props = defineProps<{
  productInfo: string | null;
}>();

const emit = defineEmits<{
  (e: "update:productInfo", value: string): void;
}>();

const editorRef = ref();
const editorStyleHeight = window.innerWidth < 992 ? "height: 7.5rem" : "height: 10.5rem";

const productInfoComputed = computed<string>({
  get: () => {
    return props.productInfo ?? "";
  },
  set: (value) => {
    emit("update:productInfo", value);
  },
});

const handleEscape = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    event.stopPropagation();
    const editorComponent = editorRef.value;
    if (editorComponent) {
      const inputElement = editorComponent.$el.querySelector(".p-editor-content .ql-editor");
      if (inputElement) {
        inputElement.blur();
        nextTick(() => {
          document.getElementById("editor-wrapper")?.focus();
        });
      }
    }
  }
};

const quillLoaded = () => {
  // Remove tab functionality from quill editor
  try {
    editorRef.value.quill.keyboard.bindings[9] = null;

    const toolbarItems: NodeList = editorRef.value?.$el?.querySelectorAll(".ql-picker-label");
    toolbarItems &&
      toolbarItems.length > 0 &&
      toolbarItems.forEach((item) => {
        item instanceof HTMLElement && item.setAttribute("tabindex", "-1");
      });

    const listItems: NodeList = editorRef.value?.$el?.querySelectorAll(".ql-list");
    listItems &&
      listItems.length &&
      listItems.forEach((item: Node) => {
        item instanceof HTMLElement && item.setAttribute("tabindex", "-1");
      });

    const buttons = [
      ".ql-bold",
      ".ql-italic",
      ".ql-underline",
      ".ql-stroke",
      ".ql-link",
      ".ql-image",
      ".ql-code-block",
      ".ql-clean",
    ];
    buttons.forEach((button) => {
      const element = editorRef.value?.$el?.querySelector(button);
      element && element.setAttribute("tabindex", "-1");
    });

    // This hack is necessary because of https://github.com/primefaces/primevue/issues/5369
    // Broken buttons are instantiated when v-model of editor is changed, and we don't want them to be tabable.
    const specialNeedsButtons = ["select.ql-color", "select.ql-background"];
    const startTime = Date.now();
    const tabIndexInterval = setInterval(() => {
      specialNeedsButtons.forEach((button) => {
        const element = editorRef.value?.$el?.querySelector(button);
        element && element.setAttribute("tabindex", "-1");
      });
      Date.now() - startTime > 5000 && clearInterval(tabIndexInterval);
    }, 200);
  } catch (e) {
    console.error(e);
  }
};
</script>

<style scoped lang="scss">
// Editor
.c-editor-container {
  height: 100%;
  border-radius: var(--card-border-radius);
  outline: none;
  &:focus {
    box-shadow: 0 0 0 0.1rem var(--active-component-color);
  }
}
.c-editor-container :deep(.p-editor-toolbar) {
  border-radius: var(--card-top-border-radius);
  background-color: var(--primary-color);
}

.c-editor-container :deep(.p-editor-toolbar.ql-snow .ql-picker .ql-picker-label) {
  color: var(--primary-color-text);
}
.c-editor-container :deep(.p-editor-toolbar.ql-snow .ql-stroke) {
  stroke: var(--primary-color-text);
}
// Editor END

:deep(.p-editor-container .p-editor-content.ql-snow) {
  border: none;
}

.empty-editor {
  min-height: 100%;
  padding: 1rem;
}
</style>
