import { NIL as emptyUuid, v4 as uuidv4 } from "uuid";

export class SupplierPrice {
  id = uuidv4();
  supplierId = emptyUuid;
  replacementCost = 0;
  currencyIso = "";
  productNumber = "";
  productCustomsPrice = 0;
  productFreightPrice = 0;
  purchasePrice = 0;
  isDefaultSupplier = false;
  estimatedDeliveryDays = 0;

  constructor(supplierId: string, currencyIso: string, estimatedDeliveryDays: number) {
    this.supplierId = supplierId;
    this.currencyIso = currencyIso;
    this.estimatedDeliveryDays = estimatedDeliveryDays;
  }
}
