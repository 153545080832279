<template>
  <PrimeDialog
    v-model:visible="visible"
    :closable="false"
    :header="t('product-hierarchy-dialog.add-dialog-header')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    :style="{ width: '20vw' }"
    @show="onShow"
    @keydown.esc="closeDialog"
    class="c-product-hierarchy-dialog-dialog"
  >
    <div class="c-product-hierarchy-dialog-content mt-4">
      <div class="flex overflow-hidden align-items-center">
        <div class="flex-grow-1 mt-3">
          <ProductGroupNameInput
            id="level-1"
            :productGroupNames="productGroupNamesComputed"
            v-model:selectedLevelId="selectedLevel1Id"
          />

          <small class="p-error" v-if="val.$error" data-testid="level-1-error">
            {{ val.$errors[0].$message }}
          </small>
        </div>
      </div>
      <div class="flex overflow-hidden align-items-center">
        <div class="flex-grow-1 mt-3">
          <ProductGroupNameInput
            id="level-2"
            :productGroupNames="productGroupNamesComputed"
            v-model:selectedLevelId="selectedLevel2Id"
            :disabled="selectedLevel1Id == '' ? true : false"
          />
        </div>
      </div>
      <div class="flex overflow-hidden align-items-center">
        <div class="flex-grow-1 mt-3">
          <ProductGroupNameInput
            id="level-3"
            :productGroupNames="productGroupNamesComputed"
            v-model:selectedLevelId="selectedLevel3Id"
            :disabled="selectedLevel2Id == '' ? true : false"
          />
        </div>
      </div>

      <div v-if="levels > 0">
        <div class="flex overflow-hidden align-items-center">
          <div class="flex-none align-items-center px-5 mt-2">
            <label for="level-4">
              {{ t(`product-hierarchy-dialog.label-level-4`) }}
            </label>
          </div>
          <div class="flex-grow-1 mt-3">
            <ProductGroupNameInput
              id="level-4"
              :productGroupNames="productGroupNamesComputed"
              v-model:selectedLevelId="selectedLevel4Id"
              :disabled="selectedLevel3Id == '' ? true : false"
            />
          </div>
        </div>
      </div>

      <div v-if="levels > 1">
        <div class="flex overflow-hidden align-items-center">
          <div class="flex-none align-items-center px-5 mt-2">
            <label for="level-5">
              {{ t(`product-hierarchy-dialog.label-level-5`) }}
            </label>
          </div>
          <div class="flex-grow-1 mt-3">
            <ProductGroupNameInput
              id="level-5"
              :productGroupNames="productGroupNamesComputed"
              v-model:selectedLevelId="selectedLevel5Id"
              :disabled="selectedLevel4Id == '' ? true : false"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-content-center mt-3">
        <PrimeButton
          text
          class="c-circular-button2 mr-3"
          @click="levels++"
          data-testid="btn-add-more-levels"
          :disabled="levels > 1"
        >
          <i class="pi pi-plus c-default-btn-bg c-circular-icon"></i>
          <span class="px-3">{{ t("product-hierarchy-dialog.button-add-levels") }}</span>
        </PrimeButton>
      </div>
    </div>

    <template #footer>
      <div class="flex flex-row-reverse justify-content-between">
        <div class="flex align-items-center justify-content-center">
          <PrimeButton
            :label="t('common.save')"
            data-testid="btn-save-product-hierarchy"
            @click="onSave"
            class="c-dialog-default-button c-dialog-success-button"
          />
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-dialog-btn"
            @click="closeDialog"
            class="c-dialog-default-button"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref, nextTick, ComponentPublicInstance, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { useAuth } from "@cumulus/event-bus";

import { ProductHierarchy } from "../model/ProductHierarchy";
import { ProductGroupNameByLanguage } from "../model/ProductGroupNameByLanguage";
import { LanguageIsoType } from "../model/LanguageIsoType";
import { useProductHierarchyStore } from "../api/ProductHierarchyStore";
import ProductGroupNameInput from "./ProductGroupNameInput.vue";

const productHierarchyStore = useProductHierarchyStore();
const { productGroupNames } = storeToRefs(productHierarchyStore);

const { t } = useI18n();

const props = defineProps<{
  showDialog: boolean;
}>();

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});

const { getUser } = useAuth();
const languageIso = ref<LanguageIsoType>(LanguageIsoType.English);

onMounted(async () => {
  languageIso.value =
    ((await (await getUser()).getEmployee()?.languageCode) as LanguageIsoType) ?? LanguageIsoType.English;
});

const productGroupNamesComputed = computed(() =>
  productGroupNames.value.map((productGroupName) =>
    ProductGroupNameByLanguage.createFromProductGroupName(productGroupName, languageIso.value)
  )
);

const selectedLevel1Id = ref("");
const selectedLevel2Id = ref("");
const selectedLevel3Id = ref("");
const selectedLevel4Id = ref("");
const selectedLevel5Id = ref("");

const level1Ref = ref<ComponentPublicInstance | null>(null);
const onShow = async () => {
  nextTick(async () => {
    if (level1Ref.value != null) {
      await timeout(200); //Wait for the dialog to be rendered before focus dropdown
      level1Ref.value.$el.click();
    }
  });
};

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    return;
  }

  const productHierarchy = new ProductHierarchy();
  productHierarchy.level1 = getGroup(selectedLevel1Id.value);
  productHierarchy.level2 = getGroup(selectedLevel2Id.value);
  productHierarchy.level3 = getGroup(selectedLevel3Id.value);
  productHierarchy.level4 = getGroup(selectedLevel4Id.value);
  productHierarchy.level5 = getGroup(selectedLevel5Id.value);

  const success = await productHierarchyStore.addProductHierarchy(productHierarchy);
  if (success) {
    closeDialog();
  }
};

const getGroup = (id: string) => {
  if (id === "") {
    return null;
  }

  return productGroupNames.value.find((productGroupName) => productGroupName.id === id) ?? null;
};

const closeDialog = async () => {
  visible.value = false;
};

const levels = ref(0);

const rules = {
  selectedLevel1Id: {
    required,
  },
};

const val = useVuelidate(rules, { selectedLevel1Id });
</script>

<style lang="scss" scoped>
.c-default-btn-bg {
  background-color: var(--success-btn-bg);
  font-size: 8px;
  padding: 0.4rem;
}
</style>
