<template>
  <ProductToolbar
    :showSidebar="showSidebar"
    @toggleFilterSideBarClicked="onToggleFilterSidebar"
    v-model:filters="filtersComputed"
    :manufacturers="manufacturers"
  />
  <div class="c-product-search">
    <div class="flex max-height">
      <div class="flex-auto">
        <div class="c-card">
          <Suspense>
            <ProductList
              :products="products"
              :loading="loading"
              :totalHits="totalHits"
              :pageSize="pageSize"
              :page="page"
              :sortField="sortField"
              :sortOrder="sortOrder"
              @update:sortOrder="onUpdateSortOrder"
              @update:sortField="onUpdateSortField"
              @update:page="onUpdatePage"
              @update:pageSize="onUpdatePageSize"
              @refreshList="onRefreshList"
            />
          </Suspense>
        </div>
      </div>
      <div class="pb-3" :class="showSidebar ? 'pl-5' : ''">
        <ProductFilterSidebar
          :manufacturers="manufacturers"
          :showSidebar="showSidebar"
          v-model:filters="filtersComputed"
          :loading="loading"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from "vue";
import { LocationQuery, useRoute, useRouter } from "vue-router";

import { Product } from "@/repositories/product/model/Product";

import { ProductSearchRequest } from "@/repositories/search/model/ProductSearchRequest";

import ProductToolbar from "../search/ProductToolbar.vue";
import ProductList from "../components/ProductList.vue";

import { useSearch } from "@/repositories/search/SearchService";
import ProductFilterSidebar from "../search/ProductFilterSidebar.vue";
import { ProductSearchFilters } from "@/repositories/search/model/ProductSearchFilters";
import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { useManufacturer } from "@/repositories/manufacturer/ManufacturerService";
import { ProductStatus } from "@/repositories/product/model/ProductStatus";

const route = useRoute();
const { productSearch } = useSearch();

const showSidebar = ref(true);
const pageSize = ref<number>(50);
const loading = ref(false);
const query = ref<string | null>(null);
const products = ref<Product[]>([]);
const totalHits = ref(0);
const page = ref(1);
const sortField = ref("");
const sortOrder = ref(-1);

const filters = ref<ProductSearchFilters | null>(null);
const manufacturers = ref<Manufacturer[]>([]);

const { getManufacturers } = useManufacturer();

watch(
  () => route.query,
  async (newQuery) => {
    if (newQuery.search !== undefined) {
      await loadSearchParams(newQuery);
    }
  }
);

onMounted(async () => {
  loading.value = true;

  loadSearchParams(route.query);
});

const loadSearchParams = async (locationQuery: LocationQuery) => {
  const q = decodeURIComponent((locationQuery.search as string) ?? "");
  const filter = setFiltersFromUrlParams(
    locationQuery.manufacturers as string,
    locationQuery.productStatuses as string,
    locationQuery.productNumbers as string,
    locationQuery.productNames as string
  );

  if (q !== query.value || JSON.stringify(filter) !== JSON.stringify(filters.value)) {
    query.value = q;
    filters.value = filter;
    await search();
  }
};

const setFiltersFromUrlParams = (
  manufacturers: string,
  productStatuses: string,
  productNumbers: string,
  productNames: string
): ProductSearchFilters => {
  const filters = new ProductSearchFilters();
  if (manufacturers) {
    filters.manufacturers = decodeURIComponent(manufacturers).split(";");
  }

  if (productStatuses) {
    filters.productStatuses = decodeURIComponent(productStatuses).split(";") as ProductStatus[];
  }

  if (productNumbers) {
    filters.productNumbers = decodeURIComponent(productNumbers).split(";");
  }

  if (productNames) {
    filters.productNames = decodeURIComponent(productNames).split(";");
  }
  return filters;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const search = async () => {
  try {
    manufacturers.value = await getManufacturers();
    const request = new ProductSearchRequest(query.value ?? "");
    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    request.filters = filters.value ?? new ProductSearchFilters();

    const response = await productSearch(request);
    products.value = response.products;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const filtersComputed = computed<ProductSearchFilters>({
  get: () => filters.value ?? new ProductSearchFilters(),
  set: async (value) => await onFilterUpdate(value),
});

const router = useRouter();

const onFilterUpdate = async (value: ProductSearchFilters) => {
  filters.value = value;
  const queries = JSON.parse(JSON.stringify(route.query));

  if (filters.value.manufacturers.length > 0) {
    queries.manufacturers = encodeURIComponent(filters.value.manufacturers.join(";"));
  } else {
    delete queries.manufacturers;
  }

  if (filters.value.productStatuses.length > 0) {
    queries.productStatuses = encodeURIComponent(filters.value.productStatuses.join(";"));
  } else {
    delete queries.productStatuses;
  }

  if (filters.value.productNumbers.length > 0) {
    queries.productNumbers = encodeURIComponent(filters.value.productNumbers.join(";"));
  } else {
    delete queries.productNumbers;
  }

  if (filters.value.productNames.length > 0) {
    queries.productNames = encodeURIComponent(filters.value.productNames.join(";"));
  } else {
    delete queries.productNames;
  }

  //Reload page with new filters from Url
  router.replace({ query: queries });
};
</script>

<style scoped lang="scss">
.c-product-search {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
.max-height {
  max-height: 90vh;
}
</style>
