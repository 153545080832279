<template>
  <div class="flex flex-column col-12 p-4 md:p-0 md:px-5">
    <label class="col-12 c-faded-label">{{ t("product.dimension.label") }}</label>
    <FloatLabelInputNumber
      id="product-dimension-height"
      dataTestId="product-dimension-height"
      :label="t('product.dimension.height')"
      v-model:value="dimensionHeightComputed"
      :buttonLabel="dimensionUnitLabelComputed"
      btnDataTestId="product-dimension-height-btn"
      @toggleDimensionMenu="toggleDimensionMenu"
      :isFloatingLabel="false"
    />
    <FloatLabelInputNumber
      id="product-dimension-width"
      dataTestId="product-dimension-width"
      :label="t('product.dimension.width')"
      v-model:value="dimensionWidthComputed"
      :buttonLabel="dimensionUnitLabelComputed"
      btnDataTestId="product-dimension-width-btn"
      @toggleDimensionMenu="toggleDimensionMenu"
      :isFloatingLabel="false"
    />

    <FloatLabelInputNumber
      id="product-dimension-length"
      dataTestId="product-dimension-length"
      :label="t('product.dimension.length')"
      v-model:value="dimensionLengthComputed"
      :buttonLabel="dimensionUnitLabelComputed"
      btnDataTestId="product-dimension-length-btn"
      @toggleDimensionMenu="toggleDimensionMenu"
      :isFloatingLabel="false"
    />

    <FloatLabelInputNumber
      id="product-weight"
      dataTestId="product-weight"
      :label="t('product.dimension.weight')"
      v-model:value="weightValueComputed"
      :buttonLabel="weightUnitLabelComputed"
      btnDataTestId="product-weight-unit-btn"
      @toggleDimensionMenu="toggleWeightMenu"
      :isFloatingLabel="false"
    />

    <PrimeMenu id="dimension_menu" ref="dimensionMenu" :model="dimensionItems" :popup="true" />
    <PrimeMenu id="weight_menu" ref="weightMenu" :model="weightItems" :popup="true" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref } from "vue";
import { Dimension } from "@/repositories/product/model/Dimension";
import { UnitOfSize } from "@/repositories/product/model/UnitOfSize";
import Menu from "primevue/menu";
import { UnitOfWeight } from "@/repositories/product/model/UnitOfWeight";
import { Weight } from "@/repositories/product/model/Weight";
import cloneDeep from "lodash.clonedeep";

const { t } = useI18n();
const dimensionMenu = ref<Menu>();
const weightMenu = ref<Menu>();

const props = defineProps<{
  dimension: Dimension | null;
  weight: Weight | null;
}>();

const emit = defineEmits<{
  (e: "update:dimension", value: Dimension): void;
  (e: "update:weight", value: Weight | null): void;
}>();

const defaultDimensionUnit = UnitOfSize.M;
const copyDimensionProp = () => {
  return props.dimension !== null
    ? cloneDeep(props.dimension)
    : ({
        unitOfSize: defaultDimensionUnit,
        length: 0,
        width: 0,
        height: 0,
      } as Dimension);
};

const defaultWeightUnit = UnitOfWeight.Kg;
const copyWeightProp = () => {
  return props.weight !== null
    ? cloneDeep(props.weight)
    : ({
        unitOfWeight: defaultWeightUnit,
        value: 0,
      } as Weight);
};

const dimensionUnitLabelComputed = computed(() => {
  return props.dimension ? props.dimension.unitOfSize.toLowerCase() : defaultDimensionUnit.toLowerCase();
});

const weightUnitLabelComputed = computed(() => {
  return props.weight ? props.weight.unitOfWeight.toLowerCase() : defaultWeightUnit.toLowerCase();
});

const dimensionHeightComputed = computed({
  get: () => {
    return props.dimension?.height ?? 0;
  },
  set: (value) => {
    const dimensionCopy = copyDimensionProp();
    dimensionCopy.height = value;
    emit("update:dimension", dimensionCopy);
  },
});

const dimensionWidthComputed = computed({
  get: () => {
    return props.dimension?.width ?? 0;
  },
  set: (value) => {
    const dimensionCopy = copyDimensionProp();
    dimensionCopy.width = value;
    emit("update:dimension", dimensionCopy);
  },
});

const dimensionLengthComputed = computed({
  get: () => {
    return props.dimension?.length ?? 0;
  },
  set: (value) => {
    const dimensionCopy = copyDimensionProp();
    dimensionCopy.length = value;
    emit("update:dimension", dimensionCopy);
  },
});

const weightValueComputed = computed({
  get: () => {
    return props.weight?.value ?? 0;
  },
  set: (value) => {
    const weightCopy = copyWeightProp();
    weightCopy.value = value;
    emit("update:weight", weightCopy);
  },
});

const dimensionItems = ref([
  {
    label: t("common.options"),
    items: Object.values(UnitOfSize).map((unit) => {
      return {
        label: unit.toLowerCase(),
        icon: "pi pi-circle",
        command: () => {
          onSelectedUnit(unit);
        },
      };
    }),
  },
]);

const weightItems = ref([
  {
    label: t("common.options"),
    items: Object.values(UnitOfWeight).map((unit) => {
      return {
        label: unit.toLowerCase(),
        icon: "pi pi-circle",
        command: () => {
          onSelectedWeight(unit);
        },
      };
    }),
  },
]);

const onSelectedUnit = (selectedDimensionUnit: UnitOfSize) => {
  const dimensionCopy = copyDimensionProp();
  dimensionCopy.unitOfSize = selectedDimensionUnit;
  emit("update:dimension", dimensionCopy);
};

const onSelectedWeight = (selectedWeightUnit: UnitOfWeight) => {
  const weightCopy = copyWeightProp();
  weightCopy.unitOfWeight = selectedWeightUnit;
  emit("update:weight", weightCopy);
};

const toggleDimensionMenu = (event: Event) => {
  if (dimensionMenu.value) {
    dimensionMenu.value.toggle(event);
  }
};

const toggleWeightMenu = (event: Event) => {
  if (weightMenu.value) {
    weightMenu.value.toggle(event);
  }
};
</script>

<style>
.c-faded-label {
  color: var(--floating-label-color);
  font-size: 0.9rem;
  font-weight: normal;
}
</style>
