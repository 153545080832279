<template>
  <FloatLabelDropdownPanel
    :selectedItemName="selectedSalesUnitComputed.name"
    :label="t('product.sales-unit.label')"
    :selectLabel="t('product.sales-unit.select')"
    @toggleDropdownPanel="showSalesUnitPanel"
    id="sales-unit-dropdown"
    dataTestId="product-sales-unit"
  />
  <small class="p-error" v-if="val.salesUnit.$error" data-testid="product-sales-unit-error">
    {{ val.salesUnit.$errors[0].$message }}
  </small>
  <SalesUnitPanel ref="salesUnitPanelRef" v-model:selectedSalesUnit="selectedSalesUnitComputed" />
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import SalesUnitPanel from "./SalesUnitPanel.vue";
import { SalesUnit } from "@/repositories/sales-unit/model/SalesUnit";
import { useSalesUnitStore } from "@/repositories/sales-unit/SalesUnitStore";

const salesUnitStore = useSalesUnitStore();

const props = defineProps<{
  salesUnit: string;
}>();

const emit = defineEmits<{
  (e: "update:salesUnit", value: string): void;
}>();

const { t } = useI18n();
const salesUnitPanelRef = ref();

const selectedSalesUnitComputed = computed<SalesUnit>({
  get: () => salesUnitStore.salesUnitById(props.salesUnit),
  set: (value) => {
    emit("update:salesUnit", value.id);
  },
});

const showSalesUnitPanel = (event: Event) => {
  salesUnitPanelRef.value.toggle(event);
};

const rules = {
  salesUnit: {
    required: required,
  },
};

const val = useVuelidate(rules, props);

onMounted(() => {
  salesUnitStore.fetchSalesUnits();
});
</script>
