<template>
  <label for="attribute-name">{{ t("attribute.name") }}</label>
  <InputText
    id="attribute-name"
    data-testid="attribute-name"
    v-model="attributeNameComputed"
    class="inputfield w-full"
    autofocus="true"
    :placeholder="t('placeholder.type', { property: t('attribute.name').toLowerCase() })"
  />
  <small class="p-error" v-if="val.attributeName.$error" data-testid="attribute-name-error">
    {{ val.attributeName.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { Attribute } from "@/repositories/attribute/model/Attribute";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  attributeName: string;
  attribute: Attribute;
  attributes: Attribute[];
}>();

const emit = defineEmits<{
  (e: "update:attributeName", value: string): void;
}>();

const { t } = useI18n();

const attributeNameComputed = computed<string>({
  get: () => props.attributeName,
  set: (value) => emit("update:attributeName", value),
});

const isUniqueAttributeName = (name: string) => {
  if (name.trim().length === 0) return true;

  const duplicateAttribute = props.attributes.find(
    (c) => c.name.toLowerCase() === name.trim().toLowerCase() && c.id !== props.attribute.id
  );
  return duplicateAttribute === undefined;
};

const rules = {
  attributeName: {
    required: required,
    unique: helpers.withMessage(t("attribute.error.already-exists"), isUniqueAttributeName),
  },
};

const val = useVuelidate(rules, props);
</script>
