<template>
  <DataTable
    ref="salesUnitTableRef"
    :value="salesUnits"
    scrollHeight="flex"
    dataKey="id"
    :loading="loading"
    :rowHover="true"
    v-model:filters="filtersSalesUnit"
    @row-select="onRowSelect"
    data-testid="sales-unit-list"
    sortField="name"
    selectionMode="multiple"
    :metaKeySelection="false"
    :sortOrder="1"
    stripedRows
    autoLayout
    :paginator="usePaginator"
    :rows="numberOfRows"
    class="c-compact-datatable"
  >
    <template #header>
      <span class="p-input-icon-left w-full">
        <i class="pi pi-search" />
        <InputText
          ref="searchInputRef"
          v-model="filtersSalesUnit['global'].value"
          :placeholder="t('common.search')"
          @keyup.enter="searchInputEnterKey"
          @keypress.tab="focusSalesUnitTable"
          @keydown.down="focusSalesUnitTable"
          class="w-full"
          data-testid="sales-unit-search"
        />
      </span>
    </template>

    <Column field="name" :header="t('product.sales-unit.name')">
      <template #body="{ data }">
        <span data-testid="sales-unit-row">{{ data.name }}</span>
      </template>
    </Column>
    <Column field="description" :header="t('product.sales-unit.description')">
      <template #body="{ data }">
        <span data-testid="sales-unit-row">{{ data.description }}</span>
      </template>
    </Column>
    <Column class="w-1rem">
      <template #body="{ data }">
        <PrimeButton
          type="button"
          icon="pi pi-pencil"
          class="p-button-text"
          :id="data.name.toLowerCase() + '-edit'"
          data-testid="sales-unit-edit"
          @click="editSalesUnit(data)"
        ></PrimeButton>
      </template>
    </Column>

    <template #loading>{{ t("product.sales-unit.loading") }}</template>

    <template #empty>
      <div id="empty-cg-filter" ref="emptyFilterRef">
        {{ t("product.sales-unit.not-found") }}
      </div></template
    >
  </DataTable>
</template>

<script setup lang="ts">
import { ref, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { SalesUnit } from "@/repositories/sales-unit/model/SalesUnit";
import { DataTableRowSelectEvent } from "primevue/datatable";
import { useSalesUnitStore } from "@/repositories/sales-unit/SalesUnitStore";
import { storeToRefs } from "pinia";

const salesUnitStore = useSalesUnitStore();

defineProps<{
  selectedSalesUnit: SalesUnit;
}>();

const emit = defineEmits<{
  (e: "createNewSalesUnit", value: SalesUnit): void;
  (e: "hideOverlayPanel", value: void): void;
  (e: "update:selectedSalesUnit", value: SalesUnit): void;
  (e: "update:reFetchSalesUnits", value: boolean): void;
}>();

const { t } = useI18n();
const emptyFilterRef = ref(null);
const usePaginator = ref(false);
const salesUnitTableRef = ref();
const numberOfRows = ref(10);
const { salesUnits, loading } = storeToRefs(salesUnitStore);

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const focusSalesUnitTable = (event: Event) => {
  event.stopPropagation();
  salesUnitTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const onRowSelect = (event: DataTableRowSelectEvent) => {
  emit("update:selectedSalesUnit", event.data as SalesUnit);
  emit("hideOverlayPanel");
};

const searchInputEnterKey = (event: Event) => {
  event.stopPropagation();
  if (emptyFilterRef.value === null) {
    focusSalesUnitTable(event);
  } else {
    const cg = new SalesUnit();
    cg.name = filtersSalesUnit.value["global"].value;
    emit("createNewSalesUnit", cg);
  }
};

const editSalesUnit = (cg: SalesUnit) => {
  emit("createNewSalesUnit", cg);
};

const filtersSalesUnit = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  name: { operator: FilterOperator.AND, constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }] },
  description: { operator: FilterOperator.AND, constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }] },
});
</script>
