<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('product.title-edit')" :itemsList="items"></ColumnOptionsMenu>

    <DataTable
      ref="productSearchResult"
      :value="products"
      v-model:expandedRows="expandedRows"
      v-model:selection="selectedProduct"
      dataKey="id"
      :autoLayout="true"
      :lazy="true"
      :paginator="true"
      responsiveLayout="scroll"
      selectionMode="single"
      @row-select="onRowSelected"
      @row-dblclick="onRowDblClicked"
      :rowClass="addGlobalSearchClass"
      :sortOrder="sortOrder"
      :sortField="sortField"
      removableSort
      @sort="onSort"
      :resizableColumns="true"
      columnResizeMode="fit"
      class="c-compact-datatable"
      stripedRows
      :loading="loading"
      :rows="pageSize"
      data-testid="product-search-table"
      :totalRecords="totalHits"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', {
          first: '{first}',
          last: '{last}',
          totalRecords: '{totalRecords}',
        })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      @page="onPage"
      tabindex="0"
      scrollable
      scrollHeight="75vh"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      @column-resize-end="onColumnResizeEnd"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field }">
          <template v-if="col.field === ProductLinesColumns.ProductImages">
            <img
              v-if="data.productImages.length > 0"
              :src="resizeImage(data.productImages[0].url, 30, 30)"
              class="c-product-image"
              width="30"
              height="30"
              :alt="`${data.productImages[0].name}-product-image`"
            />
          </template>
          <template v-else-if="col.field === ProductLinesColumns.ProductNumber">
            <span data-testId="product-number-text" class="c-product-number-text" @click="onProductNumberClicked(data)">
              {{ data.productNumber }}
            </span>
          </template>
          <template v-else-if="col.field === ProductLinesColumns.Gtin">
            <span>{{ data.gtin }}</span>
          </template>
          <template v-else-if="col.field === ProductLinesColumns.Name">
            <span>{{ data.name }}</span>
          </template>
          <template v-else-if="col.field === ProductLinesColumns.Description">
            <span>{{ data.description }}</span>
          </template>
          <template v-else-if="col.field === ProductLinesColumns.ProductInfo">
            <span>{{ data.productInfo }}</span>
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>
      <Column field="productInfo" :expander="true" />

      <template #expansion="{ data }">
        <ProductInfoExpanded :product="data" />
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
</template>
<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useRouter, useRoute, LocationQueryValue } from "vue-router";
import { useToast } from "primevue/usetoast";
import {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { NIL as emptyUuid } from "uuid";
import { useI18n } from "vue-i18n";
import ProductInfoExpanded from "../search/ProductInfoExpanded.vue";
import { useCumulusToast } from "@cumulus/toast";
import { useImageService } from "@/repositories/image/ImageService";

import { Product } from "@/repositories/product/model/Product";
import { useAuth } from "@cumulus/event-bus";
import { ProductLinesColumns } from "../models/ProductLinesColumns";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

const { getUser } = useAuth();

const { t } = useI18n();
const { resizeImage } = useImageService();

const router = useRouter();
const route = useRoute();
const toast = useCumulusToast(useToast());
const productSearchResult = ref();
const selectedProduct = ref<Product>(new Product());
const expandedRows = ref([]);
const visible = ref(false);

const props = defineProps<{
  products: Product[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:sort", value: string): void;
  (e: "refreshList"): void;
}>();

watch([() => props.products, () => props.loading], ([products, loading]) => {
  if (loading === false && products.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult(route.query?.focusResult ?? "");
      }
    });
  }
});

const productSearchColumns: DataTableColumn[] = [
  {
    field: "productImages",
    header: t("product.search.header-image"),
    sortable: false,
    class: "c-col-image p-0",
  },
  { field: "productNumber", header: t("product.search.header-product-number"), sortable: true },
  { field: "gtin", header: t("product.search.header-gtin"), sortable: true },
  { field: "name", header: t("product.search.header-name"), sortable: true },
  { field: "description", header: t("product.search.header-description"), sortable: true },
  { field: "productInfo", header: t("product.search.header-product-info"), sortable: true },
];

const initialSelectedColumns = [
  {
    field: "productImages",
    header: t("product.search.header-image"),
    sortable: false,
    class: "c-col-image p-0",
  },
  { field: "productNumber", header: t("product.search.header-product-number"), sortable: true },
  { field: "gtin", header: t("product.search.header-gtin"), sortable: true },
  { field: "name", header: t("product.search.header-name"), sortable: true },
  { field: "description", header: t("product.search.header-description"), sortable: true },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences(
  "productSearch",
  productSearchColumns,
  initialSelectedColumns,
  (await getUser()).getEmployee().id
);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) => col.field !== ProductLinesColumns.ProductNumber && col.field !== ProductLinesColumns.Name
  );
});

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    class: "c-refresh-button",
    command: () => {
      onClickProductRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const onClickProductRefresh = () => {
  emit("refreshList");
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? productSearchColumns
    : productSearchColumns.filter(
        (c) => c.field === ProductLinesColumns.ProductNumber || c.field === ProductLinesColumns.Name
      );
};

const addGlobalSearchClass = (data: Product) => {
  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedProduct.value?.id === data?.id },
    { "c-inactive": data.activeOnWebshop === false },
  ];
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openSearchProduct(event.data.id);
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  openSearchProduct(event.data.id);
};

const openSearchProduct = (productId: string) => {
  productId != null && productId !== emptyUuid
    ? router.push({ name: "product-edit", params: { id: productId } })
    : toast.add({
        severity: "warn",
        summary: t("product.toast.open-product-failed"),
        detail: t("product.search-header.no-product-selected"),
        closable: true,
      });
};

const focusSearchResult = (id: LocationQueryValue[] | string = "") => {
  if (props.products.length > 0 && productSearchResult.value) {
    const element = id === "" ? null : productSearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }
    productSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onProductNumberClicked = (product: Product) => {
  selectedProduct.value = product;
  openSearchProduct(product.id);
};
const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
</script>
<style scoped lang="scss">
.c-product-image {
  display: block;
  margin: auto;
  max-width: 30px;
}
:deep(.c-col-image) {
  padding: 0 !important;
}
:deep(.c-inactive) td {
  background-color: #fff6f6;
}
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
.c-product-number-text:hover {
  text-decoration: underline;
}
</style>
