<template>
  <FloatLabelDropdownPanel
    :selectedItemName="attributeComputed"
    @toggleDropdownPanel="emit('showAttributePanel', $event)"
    :label="label"
    :dataTestId="dataTestId ?? id"
    :id="id"
    :selectLabel="selectLabel"
  />
</template>

<script setup lang="ts">
import { AttributeType } from "@/repositories/attribute/model/AttributeType";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import { computed } from "vue";

const props = defineProps<{
  attributeType: AttributeType;
  attribute?: ProductAttribute;
  label?: string;
  selectLabel?: string;
  id?: string;
  dataTestId?: string;
}>();

const emit = defineEmits<{
  (e: "showAttributePanel", value: Event): void;
}>();

const attributeComputed = computed(() => {
  if (props.attributeType === AttributeType.Name) {
    return props.attribute && props.attribute.name ? props.attribute.name : "";
  } else {
    return props.attribute && props.attribute.name ? props.attribute.values : [];
  }
});
</script>
