<template>
  <span class="p-inputgroup">
    <InputText
      v-model="filter['global'].value"
      :placeholder="t('common.search')"
      type="text"
      data-testid="attribute-search-name"
      @focus="selectAllOnFocus"
      @keydown.enter="addNewAttribute"
      autofocus
      ref="searchAttributeInputRef"
    />
    <span class="p-inputgroup-addon">
      <i class="pi pi-search" />
    </span>
  </span>
  <DataTable
    :value="filteredAttributes"
    :loading="loading"
    class="c-compact-datatable mt-3"
    data-testid="attribute-name-table"
    :autoLayout="true"
    responsiveLayout="stack"
    breakpoint="991px"
    selectionMode="single"
    @row-select="onRowSelect"
    v-model:filters="filter"
    stripedRows
  >
    <template #empty>{{ t("product.attribute.no-attributes-found") }} </template>
    <template #loading>{{ t("product.attribute.loading") }}</template>

    <Column field="name" filterField="name" filterMatchMode="contains" :header="t(`product.attribute.name`)">
      <template #body="{ data }">
        <span data-testid="attribute-name-row">{{ data.name }}</span>
      </template>
    </Column>
  </DataTable>

  <AttributeFormDialog
    v-if="showDialog"
    v-model:showDialog="showDialog"
    :attribute="attribute"
    :attributes="attributes"
    @reloadAttributeList="fetchAttributes"
    @update:showDialog="updateShowDialog"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, computed, watchEffect, watch } from "vue";
import { useI18n } from "vue-i18n";
import { InternalServerError, NotFoundError } from "@cumulus/http";
import { attributeApi } from "@/repositories/attribute/AttributeApi";
import { useAuth } from "@cumulus/event-bus";
import { Attribute } from "@/repositories/attribute/model/Attribute";
import { DataTableRowSelectEvent } from "primevue/datatable";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { FilterMatchMode } from "primevue/api";
import { ProductAttribute } from "@/repositories/product/model/ProductAttribute";
import AttributeFormDialog from "@/attribute/components/AttributeFormDialog.vue";

const props = defineProps<{
  productAttributes: ProductAttribute[];
}>();

const emit = defineEmits<{
  (e: "onAttributeSelect", value: Attribute): void;
  (e: "isDialogOpen", value: boolean): void;
}>();

const { t } = useI18n();
const loading = ref(false);
const attributes = ref<Attribute[]>([]);
const toast = useCumulusToast(useToast());
const errorReason = ref("");
const loadFailed = ref(false);
const filter = ref({ global: { value: "", matchMode: FilterMatchMode.CONTAINS } });

const searchAttributeInputRef = ref();

const attribute = ref(new Attribute());
const showDialog = ref(false);
watchEffect(() => {
  emit("isDialogOpen", showDialog.value);
});
watch(showDialog, (showDialog) => {
  if (showDialog) {
    attribute.value.name = filter.value.global.value;
  }
});

const updateShowDialog = (value: boolean) => {
  if (value === false) {
    searchAttributeInputRef.value?.$el.focus();
  }
};

const { getAuthHeaders } = useAuth();

const onRowSelect = (event: DataTableRowSelectEvent) => {
  const attribute = event.data as Attribute;

  emit("onAttributeSelect", attribute);
};

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const addNewAttribute = () => {
  attribute.value = new Attribute();
  showDialog.value = true;
};

const filteredAttributes = computed<Attribute[]>(() => {
  return attributes.value.filter((attribute: Attribute) => {
    const index = props.productAttributes?.findIndex((c: ProductAttribute) => c.id === attribute.id);
    return index === -1;
  });
});

const fetchAttributes = async () => {
  try {
    loading.value = true;
    const authHeaders = await getAuthHeaders();
    attributes.value = await attributeApi.getAll(authHeaders);
  } catch (error) {
    let errorMessage = "";
    if (error instanceof NotFoundError) {
      errorMessage = t("product.attribute.not-found");
    }

    if (error instanceof InternalServerError) {
      errorMessage = "Server error";
      toast.add({
        severity: "error",
        summary: t("product.attribute.server-error"),
        detail: errorMessage,
        closable: true,
      });
    }

    errorReason.value = errorMessage;
    loadFailed.value = true;
  } finally {
    loading.value = false;
  }
};
onMounted(fetchAttributes);
</script>
