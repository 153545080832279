<template>
  <div @keydown.esc.stop="$emit('cancel')">
    <div class="field">
      <ManufacturerName v-model:manufacturerName="manufacturerRef.name" />
    </div>
    <div class="field">
      <ManufacturerDescription v-model:manufacturerDescription="manufacturerRef.description" />
    </div>

    <div class="field">
      <ManufacturerWebsiteUrl v-model:manufacturerWebsiteUrl="manufacturerRef.websiteUrl" />
    </div>

    <div class="field">
      <SelectManufacturerStatusType v-model:manufacturerStatus="manufacturerRef.manufacturerStatus" />
    </div>

    <div class="field">
      <label for="manufacturer-logo">
        {{ t("product.manufacturer.logo") }}
      </label>
      <div class="flex flex-nowrap">
        <ManufacturerLogo v-model:logo="manufacturerRef.logo" />
      </div>
    </div>

    <div class="w-full">
      <div class="flex justify-content-between">
        <PrimeButton
          type="button"
          :label="t('common.cancel')"
          class="c-dialog-default-button"
          @click="emit('cancel')"
          tabindex="1"
        ></PrimeButton>

        <PrimeButton
          v-if="editModeComputed"
          type="button"
          :label="t('common.delete')"
          class="c-delete-button"
          @click="confirmDeleteManufacturer($event)"
          data-testid="btn-delete-manufacturer"
          :disabled="saving"
        ></PrimeButton>

        <PrimeButton
          type="button"
          :label="editModeComputed ? t('common.save') : t('common.add')"
          class="c-success-button"
          @click="onCommit"
          data-testid="btn-commit-manufacturer"
          :disabled="saving"
        ></PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import useValidate from "@vuelidate/core";
import { useConfirm } from "primevue/useconfirm";
import { storeToRefs } from "pinia";

import { Manufacturer } from "@/repositories/manufacturer/model/Manufacturer";
import { NewManufacturer } from "@/repositories/manufacturer/model/NewManufacturer";
import { UpdateManufacturer } from "@/repositories/manufacturer/model/UpdateManufacturer";
import { NIL as emptyUuid } from "uuid";
import SelectManufacturerStatusType from "./SelectManufacturerStatusType.vue";
import ManufacturerLogo from "./ManufacturerLogo.vue";
import ManufacturerName from "./ManufacturerName.vue";
import { useManufacturerStore } from "@/repositories/manufacturer/ManufacturerStore";
import ManufacturerDescription from "./ManufacturerDescription.vue";
import ManufacturerWebsiteUrl from "./ManufacturerWebsiteUrl.vue";
import cloneDeep from "lodash.clonedeep";

const props = defineProps<{
  manufacturer: Manufacturer;
}>();

const emit = defineEmits<{
  (e: "cancel", value: void): void;
  (e: "manufacturerCreated", value: Manufacturer): void;
  (e: "manufacturerUpdated", value: Manufacturer): void;
  (e: "manufacturerDeleted", value: Manufacturer): void;
}>();

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();
const manufacturerStore = useManufacturerStore();
const { saving } = storeToRefs(manufacturerStore);

const manufacturerRef = ref<Manufacturer>(new Manufacturer());

const editModeComputed = computed<boolean>(() => {
  return manufacturerRef.value.id !== emptyUuid;
});

onMounted(() => {
  manufacturerRef.value = cloneDeep(props.manufacturer);
});

const onCommit = async () => {
  val.value.$touch();
  await val.value.$validate();

  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  if (editModeComputed.value) {
    const updateManufacturer = UpdateManufacturer.createUpdateManufacturer(manufacturerRef.value);
    await manufacturerStore.updateManufacturer(updateManufacturer);

    toast.add({
      severity: "success",
      summary: t("product.manufacturer.toast.success-update.summary"),
      detail: t("product.manufacturer.toast.success-update.detail", { name: manufacturerRef.value.name }),
      closable: true,
    });

    emit("manufacturerUpdated", manufacturerRef.value);
  } else if (!editModeComputed.value) {
    const newManufacturer = NewManufacturer.createNewManufacturer(manufacturerRef.value);

    await manufacturerStore.createManufacturer(newManufacturer);

    emit("manufacturerCreated", manufacturerRef.value);
  }
};

const confirmDeleteManufacturer = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      await manufacturerStore.deleteManufacturer(manufacturerRef.value.id);
      toast.add({
        severity: "success",
        summary: t("product.manufacturer.toast.success-deleted.summary"),
        detail: t("product.manufacturer.toast.success-deleted.detail", { name: manufacturerRef.value.name }),
        closable: true,
      });
      emit("manufacturerDeleted", manufacturerRef.value);
    },
  });
};

const val = useValidate();
</script>

<style lang="scss" scoped>
.c-delete-button {
  background-color: var(--delete-btn-bg);
  border: none;
}

.c-success-button {
  background-color: var(--success-btn-bg);
  border: none;
}
</style>
