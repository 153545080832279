<template>
  <label for="attribute-name">{{ t("attribute.values") }}</label>
  <div v-if="attributeValues.length === 0">{{ t("attribute.no-attributes-found") }}</div>
  <div v-for="(value, index) in attributeValues" :key="index" data-testid="attribute-values">
    <div class="formgrid grid">
      <div class="field col-12 md:col-6 flex flex-column">
        <InputText
          id="attribute-value"
          type="text"
          :modelValue="value"
          class="inputfield w-full"
          data-testid="attribute-value"
          @update:modelValue="updateAttribute($event as string, index)"
        />
      </div>
      <div class="field col-12 md:col-6 flex flex-column">
        <PrimeButton
          id="attribute-value-delete"
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text m-auto"
          @click="onConfirmDelete($event, index)"
        />
      </div>
    </div>
  </div>
  <div class="formgrid grid">
    <div class="col-12">
      <small class="p-error" v-if="val.$error" id="attribute-values-error" data-testid="attribute-values-error">
        {{ val.$errors[0].$message }}
      </small>
    </div>
    <PrimeButton
      type="button"
      class="p-button-text p-button-sm c-add-freight"
      data-testid="btn-add-attribute-value"
      @click="addAttributeValue"
    >
      <i class="c-plus"></i>
      <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
    </PrimeButton>
  </div>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();

const props = defineProps<{
  attributeValues: string[];
}>();

const emit = defineEmits<{
  (e: "update:attributeValues", value: string[]): void;
}>();

const { t } = useI18n();

const updateAttribute = (value: string, index: number) => {
  const newAttributeValues = [...props.attributeValues];
  newAttributeValues[index] = value;
  emit("update:attributeValues", newAttributeValues);
};

const addAttributeValue = () => {
  const newAttributeValues = [...props.attributeValues];
  newAttributeValues.push("");

  emit("update:attributeValues", newAttributeValues);
};

const removeAttributeValue = (index: number) => {
  const newAttributeValues = [...props.attributeValues];
  newAttributeValues.splice(index, 1);
  emit("update:attributeValues", newAttributeValues);
};
const onConfirmDelete = (event: Event, index: number) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      removeAttributeValue(index);
    },
  });
};

const isUniqueAttributeValue = (value: string[]) => {
  return (
    value.filter((item) => {
      return (
        value.filter((item2) => {
          return item.toLowerCase().trim() === item2.toLowerCase().trim();
        }).length > 1
      );
    }).length === 0
  );
};

const rules = {
  attributeValues: {
    required: helpers.withMessage(t("attribute.error.fill-all-values"), (value: string[]) => {
      return value.filter((v) => v.trim().length > 0).length === value.length;
    }),
    atLeastOne: helpers.withMessage(t("attribute.error.at-least-one-value"), (value: string[]) => {
      return value.filter((v) => v.trim().length > 0).length > 0;
    }),
    unique: helpers.withMessage(t("attribute.error.duplicate"), (value: string[]) => {
      return isUniqueAttributeValue(value);
    }),
  },
};

const val = useVuelidate(rules, props);
</script>
